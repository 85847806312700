import axios from 'axios';
import { makeCancelable } from '../store/utils';

export const flush_cache = () => {
    return (dispatch, getState) => {
        return axios
            .put(`/api/1.0/backend/flush`)
            .then((data) => {
                return;
            })
            .catch((error) => {
                //                throw error;
            });
    };
};

export const get_all_vthings = () => (dispatch, getState) =>
    makeCancelable(
        axios.get(`/api/1.0/backend/vthing/list`).then(({ data }) => {
            return data.list;
        }),
    );

export const get_prompts = (workspace) => (dispatch, getState) =>
    makeCancelable(
        axios.get(`/api/1.0/backend/${encodeURIComponent(workspace)}/prompt`).then(({ data }) => {
            return data.prompts;
        }),
    );

export const get_prompt = (workspace, id) => (dispatch, getState) =>
    makeCancelable(
        axios.get(`/api/1.0/backend/${encodeURIComponent(workspace)}/prompt/${encodeURIComponent(id)}`).then(({ data }) => {
            return data.prompt;
        }),
    );

export const update_prompt = (workspace, prompt) => (dispatch, getState) =>
    makeCancelable(
        axios.put(`/api/1.0/backend/${encodeURIComponent(workspace)}/prompt/${encodeURIComponent(prompt.id)}`, prompt).then(({ data }) => {
            return true;
        }),
    );

export const get_vtm_secret = (workspace, vthing_id) => (dispatch, getState) =>
    makeCancelable(
        axios.get(`/api/1.0/workspaces/${encodeURIComponent(workspace)}/vthing/${encodeURIComponent(vthing_id)}/secret`).then(({ data }) => {
            return data.configuration;
        }),
    );
