/**
 * External Dependencies
 */
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';

/**
 * Internal Dependencies
 */
import Icon from '../../../icon';
import { AgoraWorkspaceContext } from '..';
import InputClipBoard from '../../../input-clipboard';
import ODataLogs from '../../odata';
import { post_workspace, put_labelWorspace, put_languageWorkspace } from '../../../../actions';
import { makeCancelable } from '../../../../store/utils';

export const languages = [
    { label: 'Automatic Language Detection', value: 'auto' },
    { label: 'Chinese', value: 'chinese' },
    { label: 'Dutch', value: 'dutch' },
    { label: 'English', value: 'english' },
    { label: 'French', value: 'french' },
    { label: 'German', value: 'german' },
    { label: 'Italian', value: 'italian' },
    { label: 'Japanese', value: 'japanese' },
    { label: 'Polish', value: 'polish' },
    { label: 'Portuguese', value: 'portuguese' },
    { label: 'Russian', value: 'russian' },
    { label: 'Spanish', value: 'spanish' },
    { label: 'Vietnamese', value: 'vietnamese' },
];

/*=============================================
=            Control declaration              =
=============================================*/
const SettingsConfiguration = ({ app, post_workspace, put_labelWorspace, put_languageWorkspace }) => {
    /*=============================================
    =            State declaration                =
    =============================================*/
    const { edit, companyId, stats, setCanSave, setOnSave, currentWS, setCurrentWS, handleClose } = useContext(AgoraWorkspaceContext);
    const [error, setError] = useState('');
    const [language, setLanguage] = useState(() => {
        if (!edit || !currentWS) return languages[0];
        if (currentWS && currentWS.language) {
            for (let i = 0; i != languages.length; i++) {
                if (currentWS.language == languages[i].value) {
                    return languages[i];
                }
            }
        }
        return languages[0];
    });
    const [name, setName] = useState(() => currentWS?.label || '');
    const cancelables = useRef([]);
    /*=============================================
    =            Actions section                  =
    =============================================*/

    const memo_stats = useMemo(() => {
        if (stats == null) return '';
        const result = `${(Math.floor((stats.totalSize / 1024 / 1024 / 1024) * 100) / 100).toFixed(2)} GB`;

        if (stats.totalSize == 0) return result;
        const logs = Math.round(((stats.sizeLogs || 0) * 100) / stats.totalSize);
        const verbs = Math.round(((stats.sizeVerbatims || 0) * 100) / stats.totalSize);
        const intents = Math.round(((stats.sizeIntents || 0) * 100) / stats.totalSize);
        return `${result} : logs (${logs}%) verbatims (${verbs}%) intents (${intents}%)`;
    }, [stats]);

    /*=============================================
    =            Submit section                   =
    =============================================*/
    const onsubmit = useCallback(() => {
        const newWS = { ...currentWS };

        const promises = [];
        cancelables.current?.forEach((p) => p?.cancel());
        cancelables.current = [];
        if (edit) {
            if (currentWS.language != language.value) {
                const cancelable = makeCancelable(put_languageWorkspace(currentWS.workspace, language.value));
                cancelables.current.push(cancelable);
                promises.push(
                    cancelable.promise
                        .then(() => {
                            newWS.language = language.value;
                            return true;
                        })
                        .catch((ex) => {
                            return !(ex?.isCanceled === true);
                        }),
                );
            }
            if (currentWS.label != name && name.length >= 3) {
                const cancelable = makeCancelable(put_labelWorspace(currentWS.workspace, name));
                cancelables.current.push(cancelable);
                promises.push(
                    cancelable.promise
                        .then(() => {
                            newWS.label = name;
                            return true;
                        })
                        .catch((ex) => {
                            return !(ex?.isCanceled === true);
                        }),
                );
            }
        } else {
            const cancelable = makeCancelable(post_workspace(name, companyId, language.value));
            cancelables.current.push(cancelable);
            promises.push(
                cancelable.promise
                    .then((u) => {
                        newWS.workspace = name;
                        newWS.language = language.value;
                        return true;
                    })
                    .catch((error) => {
                        if (error.isCanceled === true) return false;
                        setError('Unable to create the workspace');
                        return false;
                    }),
            );
        }
        Promise.all(promises).then((results) => {
            if (results.every((r) => r === true)) {
                if (edit) {
                    setCurrentWS(newWS);
                } else {
                    handleClose(newWS);
                }
            }
        });
    }, [companyId, currentWS, edit, handleClose, language.value, name, post_workspace, put_labelWorspace, put_languageWorkspace, setCurrentWS]);

    const needToSave = useMemo(() => {
        setError('');
        if (!edit) {
            return name.length > 0;
        }
        return currentWS?.language !== language.value || currentWS?.label !== name;
    }, [currentWS?.label, currentWS?.language, edit, language.value, name]);

    useEffect(() => {
        setCanSave(needToSave);
    }, [needToSave, setCanSave]);

    useEffect(() => {
        setOnSave(onsubmit);
    }, [onsubmit, setOnSave]);

    /*=============================================
    =            Unmount section                  =
    =============================================*/
    useEffect(() => {
        return () => {
            cancelables.current?.forEach((p) => p?.cancel());
        };
    }, []);

    /*=============================================
    =            Render section                   =
    =============================================*/
    return useMemo(() => {
        return currentWS || !edit ? (
            <>
                {error && (
                    <div>
                        <Label style={{ color: '#FF6347' }}>{error}</Label>
                    </div>
                )}
                {edit && (
                    <FormGroup style={{ marginBottom: 0 }}>
                        <Icon style={{ width: 20, height: 20, marginTop: -5 }} name="database" />{' '}
                        <Label className="h4" for="Stats">
                            {memo_stats}
                        </Label>
                    </FormGroup>
                )}
                <FormGroup>
                    <Label for="imageName">Workspace name</Label>
                    <Input
                        placeholder="Type your workspace neme here"
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        onChange={({ target: { value } }) => setName(value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="imageName">Default Language</Label>
                    <Select
                        classNamePrefix="agora"
                        name="language"
                        id="language"
                        isClearable={false}
                        defaultValue={null}
                        value={language}
                        options={languages}
                        onChange={setLanguage}
                        placeholder={'Select a default language'}
                        noOptionsMessage={() => 'No items...'}
                    />
                </FormGroup>
                {edit && (
                    <FormGroup>
                        <Label for="odata">
                            Open Data Protocol URL (OData)<ODataLogs className="ml-15" workspace={currentWS.workspace}></ODataLogs>
                        </Label>
                        <InputClipBoard id="odata" text={`${window.location.origin}/odata/1.0/${currentWS.workspace}.svc`} />
                    </FormGroup>
                )}
            </>
        ) : (
            <></>
        );
    }, [currentWS, edit, error, language, memo_stats, name]);
};

/*=============================================
=            props declaration                =
=============================================*/
SettingsConfiguration.defaultProps = {};

SettingsConfiguration.propTypes = {
    app: PropTypes.any.isRequired,
    post_workspace: PropTypes.func.isRequired,
    put_labelWorspace: PropTypes.func.isRequired,
    put_languageWorkspace: PropTypes.func.isRequired,
};

export default connect(
    ({ app }) => ({
        app,
    }),
    { post_workspace, put_labelWorspace, put_languageWorkspace },
)(SettingsConfiguration);
